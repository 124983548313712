import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Basics = () => (
  <Layout>
    <SEO title="Basics" />
    <div>
        <h6>Keybinding</h6>
        <p>
            The first and probably most important thing you can do to prepare for arena, and any other aspect of the game, is to set up your keybinds. You realistically don't want to be clicking any of your abilities, especially in the heat of combat. If you're not sure where to start, you can take a look at my kebinds <Link to="/keybinds">here</Link>.
        </p>
        <h6>Character Movement</h6>
        <p>
            The way that you move your character will also have a large impact on how well you preform. There is never a good reason to backpedal in player vs player combat so you can safely unbind the S key off of walking backwards and use it for another ability. Turning with the keyboard also offers you no benefit, you can turn insantly with the mouse, so unbind A and D. You can set them to strafe instead or continue to use Q and E for that.
            <br></br> 
            <br></br>
            You'll want to get used to using the mouse to move primarily by holding down both mouse buttons or holding the right mouse button while pressing forward or strafe. Always turn your character by moving your mouse.
        </p>
        <h6>Spell Queue Window</h6>
        <p>
            There is a hidden Spell Queue Window value that defaults to 400ms. If you press an ability within this timeframe it will queue up and trigger on your next global if its able to. This is dangerous as the ability locks into the queue and you can no longer change your mind. Often times in arena, things move quickly and you need to change your actions with no warning. This Spell Queue Window can prevent that and be a detrement to your gameplay. To view your Spell Queue Window type <code>/dump GetCVar("SpellQueueWindow")</code> in the game's chat box. To change your Spell Queue Window type <code>/console SpellQueueWindow X</code> where X is the value you want it to be. I'd reccommend setting it to 20 ms above the normal (home) latency you play at. You can check your latency by hovering over the Game Menu button in the bottom right of your UI.
        </p>
        <h6>Camera</h6>
        <p>Zooming your camera out to its maximum distance in order to see more of the battlefield at all times will give you an advantage.</p>
        <h6>Interface Options</h6>
        <ol>
            <li>
                <p>Controls</p>
                <ul>
                    <li>Sticky Targeting - turn it off. The fastest way to self cast is to click off of your target by clicking anywhere on the gamefield. You also want to be able to deselect cc'd targets to avoid breaking their cc.</li>
                </ul>
            </li>
            <li>
                <p>Combat</p>
                <ul>
                    <li>Target of Target - turn it on. Its important to be able to see the target of your target. There is no reason to not give yourself this information.</li>
                    <li>Auto Self Cast - turn it on. Its one of the quickest ways to self cast and saves space on your bars.</li>
                    <li>Loss of Control Alerts - I'd reccommend turning this on. It makes valuable information easier to see.</li>
                </ul>
            </li>
            <li>
                <p>ActionBars</p>
                <ul>
                    <li>You'll probably want all of your extra action bars enabled (Bottom Left, Bottom Right, Right, Right 2). Very few classes can get away with not using them all and even if you can, you can add very useful targeting macros with the extra space.</li>
                    <li>Show Numbers for Cooldowns - turn it on. This is very important so you know exactly when your abilities will be off of cooldown. Being able to line things up with your partners and communicate effectively with exact times down to the second is key.</li>
                </ul>
            </li>
            <li>
                <p>Names</p>
                <ul>
                    <li>Unit Nameplates - turn on Enemy Units and Friendly Players. If you use raid markers, you don't need to turn on Friendly Players but I still reccommend it. Its important to know where players are, friendly and enemy, when they are behind walls.</li>
                    <li>Nameplate Motion Type is up to your personal preference. I use Overlapping.</li>
                </ul>
            </li>
            <li>
                <p>Camera</p>
                <ul>
                    <li>Camera Following Style - set to Never adjust camera. This is important for being able to look in other directions than you are moving and still be able to act with the mouse.</li>
                </ul>
            </li>
            <li>
                <p>Raid Profiles</p>
                <ul>
                    <li>Keep Groups Together - uncheck this and set the 'Sort By:' to 'Group'. This is important for your @party1 and @party2 macros.</li>
                    <li>Display Incoming Heals - turn this on</li>
                    <li>Display Power Bars - turn this on</li>
                    <li>Display Class Colors - turn this on</li>
                    <li>Display Pets - turn this on, especially if you are a healer.</li>
                    <li>Show Debuffs - turn this on</li>
                    <li>Display Only Dispellable Debuffs - turn this off</li>
                    <li>Frame Height and Frame Width sliders - slide these to maximum</li>
                </ul>
            </li>
        </ol>
        <h6>User Interface</h6>
        <p>Many players use addons to set up their user interface. You do not need to do this and I prefer not to. Its much easier to just play the game from anywhere when I'm not dependant on any addons, espeically UI ones. The best way, in my opinion, to set up your UI is to unlock your party frames and drag them over to the right of your screen near the arena frames. This allows you to see the enemy and friendly players without moving your eyes across the screen.</p>
        <h6>Actions Per Minute</h6>
        <p>Press your buttons quickly and often. Don't worry about pressing the same thing multiple times per cast. Don't waste your globals!</p>
        <h6>Targeting</h6>
        <p>
            You can do a lot of targeting with the keyboard and its usually advantageous. For example, if a hunter uses feign death it might take longer to move your mouse over their body and target them again whereas if you have a keybind to target their arena frame you can almost instantly retarget them. Also, if you need to do something with your mouse, such as target a totem, and then quickly target one of your team members to heal, using the keyboard to target your team will be faster and potentially save their life!
            <br></br>
            <br></br>
            Other things to note:
            <ul>
                <li>
                    You can set your focus in the arena by right-clicking on the enemy arena frames.
                </li>
                <li>
                    You can't target totems with macros. You'll have to click them.
                </li>
                <li>
                    If you're a healer and stumble upon mouseover macros to heal, don't use them. It will put you in sticky situations where you need to use your mouse for something other than healing but need to heal at the same time and you're unable to.
                </li>
                <li>
                    You can do some fancy things with macros to cast on your target's target or cast on a specific arena frame without changing your current target. Check the <Link to="/macros">macros</Link> page for more information.
                </li>
            </ul>
        </p>
        <h6>10,000 Hours</h6>
        <p>If you have big dreams, I'd suggest sticking to one class/spec as the main one that you play until you reach your goal. If you feel that your favorite spec is weak in the current meta, stick with it anyway! It eventually wont be and that will be your time to shine! You won't master anything if you hop around too often. Great athletes might play other sports from time to time but they clearly have their main focus.</p>
    </div>
    

  </Layout>
)

export default Basics
